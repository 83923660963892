/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "theme/desktop/map-poi-popup"; // css per i popup (label) sopra il marker della mappa creazione poi

// ----------------------------
// Aggiunto da vigo
// ----------------------------

.ngx-timepicker{
  border-bottom: none!important;
}
.timepicker__header {
  background-color: var(--app-main-darker)!important;
}
.clock-face__clock-hand {
  background-color: var(--app-main-darker) !important;
  &:after {
    background-color: var(--app-main-darker) !important;
  }
}
.clock-face__clock-hand_minute:before {
  border: 4px solid var(--app-main-darker) !important;
}
.timepicker__header {
  background-color: var(--app-main-darker) !important;
}
.clock-face__number > span.active {
  background-color: var(--app-main-darker) !important;
}
.timepicker-button{
  color: var(--app-main-darker)!important;
}

.text-muted{
  color: var(--app-mid-light);
}

.input-error{
  color: var(--app-danger);
  height: 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}

.unit-time-label{
  color: var(--app-mid-light);
  font-weight: 500;
  font-size: 13px;
  margin-right: 5px;
  margin-left: 1px;
}

.unit-time-label-sm{
  color: var(--app-mid-light);
  font-weight: 500;
  font-size: 10px;
  margin-right: 4px;
  margin-left: 1px;
}
// done on the fly
.compt-layout--col-weak-2.map-popup-car {
  .unit-time-label-sm {
    color: var(--app-mid-dark);
  }
}

//@import "~ng-pick-datetime-ex/assets/style/picker.min.css";
@import "~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

.owl-dt-timer-content .owl-dt-timer-input{
  color: #1e2023;
}
.owl-dt-control-arrow-button svg{
  color: #1e2023;
}
.owl-dt-timer-divider {
  color: #1e2023;
}

.gm-style .gm-style-iw-c{
  padding: 0!important;
  background-color: white;
}

.gm-style .gm-style-iw-d{
  //overflow: unset!important;
}

.gm-style .gm-style-iw-t::after{
  background: white;
  //background: linear-gradient(45deg,rgba(var(--app-mid-dark-rgb), .75) 50%,rgba(255,255,255,0) 51%,rgba(255,255,255,0) 100%);
}

.gm-ui-hover-effect {
  display: none!important;
}




$borderRadius: 5px;
$borderRadius_lg: 10px;

// $maxSize_gap50: calc(100% - 50px);
// $maxSize_gap84: calc(100% - 84px);
$maxSize_gap100: calc(100% - 100px);
$maxSize_gap168: calc(100% - 168px);



// ----------------------------
// modal
// ----------------------------
/*
--background	Background of the modal content
--border-color	Border color of the modal content
--border-radius	Border radius of the modal content
--border-style	Border style of the modal content
--border-width	Border width of the modal content
--height	Height of the modal
--max-height	Maximum height of the modal
--max-width	Maximum width of the modal
--min-height	Minimum height of the modal
--min-width	Minimum width of the modal
--width	Width of the modal
*/


.modal_alarm {
  // FIX: backdrop not showing when this is the 2nd modal
  --backdrop-opacity: var(--ion-backdrop-opacity, .32);


  @media only screen and (min-height: 600px) and (min-width: 768px) {
    --border-radius: #{$borderRadius_lg};
    border-radius: $borderRadius_lg; // Safari (doesn't work)

    --height: auto;
    --min-height: 500px;
    --max-height: #{$maxSize_gap100};

    // --width: auto;
    // --min-width: 600px;
    // --max-width: #{$maxSize_gap168};
  }

  @media only screen and (min-height: 600px) and (min-width: 992px) {
    // --min-width: 800px;
    --width: 800px;
  }

  @media only screen and (min-height: 768px) and (min-width: 768px) {
    --height: auto;
    --min-height: 600px;
    --max-height: #{$maxSize_gap168};
  }


}

.modal_alarm--small {
  // FIX: backdrop not showing when this is the 2nd modal
  --backdrop-opacity: var(--ion-backdrop-opacity, .32);


  @media only screen and (min-height: 600px) and (min-width: 768px) {
    --border-radius: #{$borderRadius_lg};
    border-radius: $borderRadius_lg; // Safari (doesn't work)

    --height: 10%; //full height
    --min-height: 360px;
    --max-height: #{$maxSize_gap100};

    // --width: auto;
    // --min-width: 600px;
    // --max-width: #{$maxSize_gap168};
  }

  @media only screen and (min-height: 600px) and (min-width: 992px) {
    // --min-width: 800px;
    --width: 800px;
  }

  @media only screen and (min-height: 768px) and (min-width: 768px) {
    --height: 10%; //full height
    --min-height: 400px;
    --max-height: #{$maxSize_gap168};
  }


}


.modal_alarm--h {
  // FIX: backdrop not showing when this is the 2nd modal
  --backdrop-opacity: var(--ion-backdrop-opacity, .32);


  @media only screen and (min-height: 600px) and (min-width: 768px) {
    --border-radius: #{$borderRadius_lg};
    border-radius: $borderRadius_lg; // Safari (doesn't work)

    --height: 80%; //full height
    --min-height: 500px;
    --max-height: #{$maxSize_gap100};

    // --width: auto;
    // --min-width: 600px;
    // --max-width: #{$maxSize_gap168};
  }

  @media only screen and (min-height: 600px) and (min-width: 992px) {
    // --min-width: 800px;
    --width: 800px;
  }

  @media only screen and (min-height: 768px) and (min-width: 768px) {
    --height: 80%; //full height
    --min-height: 600px;
    --max-height: #{$maxSize_gap168};
  }


}



.modal_alert {
  // unpolished

  --border-radius: #{$borderRadius_lg};
  border-radius: $borderRadius_lg; // Safari (doesn't work)

  --height: 320px;
  --max-height: 100%;

  --width: 550px;


  // FIX: backdrop not showing when this is the 2nd modal
  --backdrop-opacity: var(--ion-backdrop-opacity, .32);
}

.modal_big{
  // FIX: backdrop not showing when this is the 2nd modal
  --backdrop-opacity: var(--ion-backdrop-opacity, .32);

  @media only screen and (min-height: 600px) and (min-width: 768px) {
    --border-radius: #{$borderRadius_lg};
    border-radius: $borderRadius_lg; // Safari (doesn't work)
    --height: 100%;
    --min-height: 550px;
    --max-height: #{$maxSize_gap100};
    --width: 100%;
    --min-width: 730px;
    --max-width: #{$maxSize_gap100};
  }

  @media only screen and (min-height: 800px) and (min-width: 1200px) {
    --width: 1100px;
    --height: 620px;
  }
}

// * unused
.modal_ChatGroupNew {
  border-radius: #{$borderRadius};

  --height: 180px;
  --width: 400px;


  // FIX: backdrop not showing when this is the 2nd modal
  --backdrop-opacity: var(--ion-backdrop-opacity, .32);
}



.modal_full {
  --border-radius: 0;

  --height: 100%;
  --width: 100%;
}


// * unused
.modal_fullInvisible {
  --border-radius: 0;

  --height: 100%;
  --width: 100%;

  visibility: hidden;
}


// * unused
.modal_listTallThin {

  @media only screen and (min-height: 600px) and (min-width: 768px) {
    border-radius: #{$borderRadius};

    --height: calc(100% - 100px);
    --width: 460px;


    // FIX: backdrop not showing when this is the 2nd modal
    --backdrop-opacity: var(--ion-backdrop-opacity, .32);
  }

  //@media only screen and (min-height: 768px) and (min-width: 768px) {}
}


// * unused
.modal_list {

  @media only screen and (min-height: 600px) and (min-width: 768px) {
    border-radius: #{$borderRadius};

    //--height: calc(100% - 100px);
    --width: 500px;


    // FIX: backdrop not showing when this is the 2nd modal
    --backdrop-opacity: var(--ion-backdrop-opacity, .32);
  }

  //@media only screen and (min-height: 768px) and (min-width: 768px) {}
}


// * unused
.modal_listTall {

  @media only screen and (min-height: 600px) and (min-width: 768px) {
    border-radius: #{$borderRadius};

    --height: calc(100% - 100px);
    --width: 500px;


    // FIX: backdrop not showing when this is the 2nd modal
    --backdrop-opacity: var(--ion-backdrop-opacity, .32);
  }

  //@media only screen and (min-height: 768px) and (min-width: 768px) {}
}






// ----------------------------
// popover
// ----------------------------
/*
--background	Background of the popover
--box-shadow	Box shadow of the popover
--height	Height of the popover
--max-height	Maximum height of the popover
--max-width	Maximum width of the popover
--min-height	Minimum height of the popover
--min-width	Minimum width of the popover
--width	Width of the popover
*/


.popover_list {
  --width: 350px;

  .popover-viewport {
    overflow-y: hidden;
  }
}



.popover_listPlain {
  --width: auto;
}





ion-modal.auto-height {
  &.bottom {
    align-items: flex-end;
  }

  --height: auto;
  --width: auto;

  .ion-page {
    position: relative;
    display: block;
    contain: content;

    .container {
      border-radius: 6px;
      min-height: 20vh;
      min-width: 30vw;
      overflow: auto;
      padding: 1rem 1.5rem 0.5rem 1.5rem;
    }
  }
}

ion-modal.modal {
  &.bottom {
    align-items: flex-end;
  }

  .ion-page {
    position: relative;
    display: block;
    contain: content;

    .container {
      border-radius: 6px;
      min-height: 20vh;
      min-width: 30vw;
      overflow: auto;
      padding: 1rem 1.5rem 0.5rem 1.5rem;
    }
  }
}

ion-modal>.ion-page {
  position: absolute!important;
}

ion-modal>.ion-delegate-host{
  position: relative!important;
}

ion-modal.modal-default.show-modal ~ ion-modal.modal-default{
  --backdrop-opacity: 0.4!important;
}




































